import React, { useState } from "react";

export default function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    service: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let formErrors = {};

    // Name validation
    if (!formData.name) formErrors.name = "Enter a Valid Name";

    // Email validation
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email))
      formErrors.email = "Valid email is required";

    // Phone number validation (must be 10 digits, start with 6-9)
    if (!formData.phoneNumber) {
      formErrors.phoneNumber = "Phone number is required";
    } else if (!/^[6-9]\d{9}$/.test(formData.phoneNumber)) {
      formErrors.phoneNumber =
        "Phone number must start with 6-9 and be exactly 10 digits";
    }

    // Company name validation
    if (!formData.companyName)
      formErrors.companyName = "Company name is required";

    // Service selection validation
    if (!formData.service) formErrors.service = "Please choose a service";

    // Subject validation
    if (!formData.subject) formErrors.subject = "Subject is required";

    // Message validation
    if (!formData.message) formErrors.message = "Message is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form Submitted:", formData);
      alert("Form submitted successfully!");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData({ ...formData, [name]: value });

    // Remove the error message for the field if input becomes valid
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };

      // Email validation
      if (name === "email" && /\S+@\S+\.\S+/.test(value)) {
        delete newErrors.email;
      }

      // Phone number validation
      if (name === "phoneNumber" && /^[6-9]\d{9}$/.test(value)) {
        delete newErrors.phoneNumber;
      }

      // General validation for other fields
      if (name !== "email" && name !== "phoneNumber" && value.trim() !== "") {
        delete newErrors[name];
      }

      return newErrors;
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30"
    >
      <div className="row">
        {/* Name */}
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Name</label>
          <input
            type="text"
            name="name"
            className={`cs_form_field ${errors.name ? "is-invalid" : ""}`}
            placeholder="Full Name"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <div className="text-danger">{errors.name}</div>}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        {/* Email */}
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Email</label>
          <input
            type="email"
            name="email"
            className={`cs_form_field ${errors.email ? "is-invalid" : ""}`}
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <div className="text-danger">{errors.email}</div>}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        {/* Phone Number */}
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">
            Phone Number
          </label>
          <input
            type="text"
            name="phoneNumber"
            className={`cs_form_field ${
              errors.phoneNumber ? "is-invalid" : ""
            }`}
            placeholder="Enter your phone number"
            value={formData.phoneNumber}
            onChange={handleChange}
            maxLength="10" // To prevent user from typing more than 10 digits
          />
          {errors.phoneNumber && (
            <div className="text-danger">{errors.phoneNumber}</div>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        {/* Company Name */}
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">
            Company Name
          </label>
          <input
            type="text"
            name="companyName"
            className={`cs_form_field ${
              errors.companyName ? "is-invalid" : ""
            }`}
            placeholder="Enter your company name"
            value={formData.companyName}
            onChange={handleChange}
          />
          {errors.companyName && (
            <div className="text-danger">{errors.companyName}</div>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        {/* Service */}
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Service</label>
          <select
            name="service"
            className={`cs_form_field ${errors.service ? "is-invalid" : ""}`}
            value={formData.service}
            onChange={handleChange}
          >
            <option value="">Choose a Service</option>
            <option value="sem">Search Engine Marketing (SEM)</option>
            <option value="seo">Search Engine Optimization (SEO)</option>
            <option value="smm">Social Media Marketing (SMM)</option>
          </select>
          {errors.service && (
            <div className="text-danger">{errors.service}</div>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        {/* Subject */}
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Subject</label>
          <input
            type="text"
            name="subject"
            className={`cs_form_field ${errors.subject ? "is-invalid" : ""}`}
            placeholder="Your subject"
            value={formData.subject}
            onChange={handleChange}
          />
          {errors.subject && (
            <div className="text-danger">{errors.subject}</div>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        {/* Message */}
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Message</label>
          <textarea
            cols={30}
            rows={10}
            name="message"
            className={`cs_form_field ${errors.message ? "is-invalid" : ""}`}
            placeholder="Write something..."
            value={formData.message}
            onChange={handleChange}
          />
          {errors.message && (
            <div className="text-danger">{errors.message}</div>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        {/* Submit Button */}
        <div className="col-lg-12">
          <div className="cs_height_18" />
          <button className="cs_btn cs_style_1" type="submit">
            <span>Submit</span>
            <i>
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
            </i>
          </button>
        </div>
      </div>
    </form>
  );
}
