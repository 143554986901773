import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

export default function SocialWidget() {
  return (
    <div className="cs_social_links_wrap">
      <h2>Follow Us</h2>
      <div className="cs_social_links">
        <Link to="https://www.facebook.com/people/Stealth-Digital-SD/61560966751733/">
          <Icon icon="fa-brands:facebook-f" />
        </Link>

        <Link to="https://www.linkedin.com/company/stealth-digitaly/">
          <Icon icon="fa-brands:linkedin-in" />
        </Link>

        <Link to="https://www.instagram.com/digital_marketing_stealth/">
          <Icon icon="fa-brands:instagram" />
        </Link>
      </div>
    </div>
  );
}
